import {
  MANUAL_REQUEST_MATERIAL_ID,
  MANUAL_REQUEST_MATERIAL_IDS
} from "@/constants/order.constrant"
import {
  UploadModalsResponse,
  useLazyGetModelsDetailQuery,
  useLazyGetModelsStatusQuery,
  useUploadModalsDGFMutation,
  useUploadModalsMutation
} from "@/services/apiDigifabster/model"
import {
  PreselectionResponse,
  useCreateOrderMutation,
  useCreateUploadJobMutation,
  usePreselectModelMutation,
  usePurchaseProductMutation,
  useUpdatePurchaseNoteMutation,
  useUploadDrawingsMutation
} from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { IProductStore } from "@/store/product"
import {
  fileToProduct,
  fileToProductUpdate,
  fileToProductUpdateExcludeError
} from "@/store/quote"
import { IUserStore } from "@/store/user"
import { retryOnDigifabsterTimeout } from "@/utils/httpHelper"
import { UploadFile } from "antd"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const RETRY_TIME = 5
const RETRY_DELAY = 5000

export interface IUploadData {
  orderId?: number
  files: Record<string, UploadFile>
  error?: string
}

export const useUploadModels = () => {
  const [uploadJob] = useCreateUploadJobMutation()
  const [uploadModals] = useUploadModalsDGFMutation()
  const [preselectMaterial] = usePreselectModelMutation()
  const [createOrder] = useCreateOrderMutation()
  const [purchaseProduct] = usePurchaseProductMutation()
  const [getModelsStatus] = useLazyGetModelsStatusQuery()
  const [getModelsDetail] = useLazyGetModelsDetailQuery()
  const [uploadDrawings] = useUploadDrawingsMutation()
  const [updatePurchaseNote] = useUpdatePurchaseNoteMutation()
  const [fileContent, setFileContent] = useState<Blob | null>(null)
  const dispatch = useDispatch()
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const country = userInfoNew?.country || ""

  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  const childModels = useRef<number[]>([])

  useEffect(() => {
    getFileFromPublicFolder("Manual_review.stl")
  }, [])
  const getFileFromPublicFolder = async (fileName: string) => {
    try {
      const response = await fetch(
        `${process.env.PUBLIC_URL}/assets/${fileName}`
      )
      const fileBlob = await response.blob()
      setFileContent(fileBlob)
    } catch (error) {
      console.error("Error fetching file:", error)
    }
  }

  const getConfig = (resData: PreselectionResponse) => {
    const configData = Object.keys(resData).map((modelId) => {
      const data = resData[modelId as any]
      const config: Record<string, string> = {}
      const material = technologies
        .flatMap((e) => e.materials)
        .find((e) => e.id === data.material)
      if (material) {
        const { filling, layerThickness, leadTime, color } = material
        if (filling && filling.length) config["filling"] = filling[0].uuid
        if (layerThickness && layerThickness.length)
          config["layer_thickness"] = layerThickness[0].uuid
        if (leadTime && leadTime.length) config["lead_time"] = leadTime[0].uuid
        if (color && color.length) config["color"] = color[0].uuid
      }

      return {
        model_id: Number(modelId),
        material_id: data.material,
        config
      }
    })

    return configData
  }

  const checkStatus = async (
    modelIds: number[]
  ): Promise<{ failed: number[]; ready: number[] }> => {
    const { data } = await getModelsStatus({ models: modelIds })
    if (!data) throw Error()

    const failed = Object.keys(data)
      .filter((e: any) => data[e] === "failed")
      .map<number>((e) => parseInt(e))
    const ready = Object.keys(data)
      .filter((e: any) => data[e] === "ready")
      .map<number>((e) => parseInt(e))

    const remainingIds = modelIds.filter((id) => !ready.includes(id))

    if (remainingIds.length) {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      const nextResult = await checkStatus(remainingIds)
      return {
        failed: [...failed, ...nextResult.failed],
        ready: [...ready, ...nextResult.ready]
      }
    }

    return { failed, ready }
  }

  const checkModels = async (modelIds: number[]) => {
    const { data, error } = await getModelsDetail({
      id: [...childModels.current, ...modelIds].join(",")
    })
    if (!data || error) throw Error()

    childModels.current = data.results.map((e) => e.child_models).flat()
    const isInProgress = data.results.some(
      (e) => e.thumb_status === "in_progress"
    )

    if (isInProgress) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          checkModels(modelIds).then(res).catch(rej)
        }, 5000)
      })
    }

    return
  }

  const checkSuitableMaterial = async (
    modelIds: number[],
    isReadyData: PreselectionResponse
  ): Promise<PreselectionResponse> => {
    const { data } = await preselectMaterial({
      models: modelIds
    })

    if (!data) return isReadyData

    const refetchId: number[] = []
    Object.keys(data).forEach((id: any) => {
      if (data[id].is_ready) {
        isReadyData[id] = data[id]
      } else {
        refetchId.push(id)
      }
    })

    if (refetchId.length) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          checkSuitableMaterial(refetchId, isReadyData).then(res).catch(rej)
        }, 1000)
      })
    } else {
      return isReadyData
    }
  }

  const getUploadSuccessModels = (models: UploadModalsResponse[]) => {
    const failedModels = models.filter((e) => e.detail)
    const successModels = models.filter((e) => !e.detail)

    return {
      failedModels,
      successModels
    }
  }

  const uploadModels = async (
    files: UploadFile[],
    unit?: string,
    option?: {
      orderId?: number
      uploadJobId?: string
      uploadInterupted?: (failedFile: UploadFile[]) => void
      onlyUploadFiles?: boolean
      onlyPurchase?: boolean
    }
  ): Promise<IUploadData> => {
    dispatch(fileToProduct(files))

    const checkUploadModels = (
      filesUploaded: UploadModalsResponse[],
      options: {
        orderId: number
        uploadJobId: string
        uploadInterupted: (failedFile: UploadFile[]) => void
        onlyUploadFiles: boolean
        onlyPurchase: boolean
      },
      unit?: string
    ) => {
      const { failedModels, successModels } =
        getUploadSuccessModels(filesUploaded)

      if (option?.uploadInterupted && failedModels.length) {
        const failedFiles = failedModels
          .map<UploadFile | undefined>((e) => {
            const file = files.find((f) => e.file_name === f.name)
            const errorMsg =
              e.detail?.includes("may only contain") ||
              e.detail?.includes("Allowed extensions")
                ? `Oops! The file format for ${file?.name} isn't supported`
                : e.detail
            if (file) {
              return {
                ...file,
                status: "error",
                error: errorMsg || "Upload failed, please try again or "
              }
            }

            return file
          })
          .filter(Boolean)
        dispatch(
          fileToProductUpdateExcludeError(
            (failedFiles as UploadFile[]).reduce(
              (pre, cur) => ({ ...pre, [cur.uid]: cur }),
              {}
            )
          )
        )
        option.uploadInterupted(failedFiles as UploadFile[])
        if (failedFiles.length) {
          uploadDefaultModels(
            failedFiles as UploadFile[],
            { ...options, onlyPurchase: false },
            unit
          )
        }
      }

      return successModels
    }

    try {
      let orderId: number
      let uploadJobId: string

      if (option?.uploadJobId) {
        uploadJobId = option.uploadJobId
      } else {
        const { data: uploadJobData } = await uploadJob()
        if (!uploadJobData) throw new Error("Upload fail")

        uploadJobId = uploadJobData.uj
      }
      if (option?.orderId) {
        orderId = option.orderId
      } else {
        const { data: order } = await createOrder({
          uploadJobId: uploadJobId
        })
        if (!order) throw new Error("Failed to create order")

        orderId = order.id
      }

      let models: UploadModalsResponse[] = []
      const options = {
        uploadInterupted: option?.uploadInterupted || (() => {}),
        onlyUploadFiles: option?.onlyUploadFiles || false,
        onlyPurchase: option?.onlyPurchase || false,
        orderId,
        uploadJobId
      }
      if (option?.onlyPurchase) {
        models = checkUploadModels(
          files
            .map<UploadModalsResponse>((e) => e.response?.data)
            .filter(Boolean),
          options,
          unit
        )
      } else {
        // UPLOAD STEP
        const uploadedFiles: UploadModalsResponse[] = []
        const chunkSize = 5

        // Split files into groups of 5 files each.
        const fileChunks = []
        for (let i = 0; i < files.length; i += chunkSize) {
          fileChunks.push(files.slice(i, i + chunkSize))
        }

        // Execute each group of files
        for (const batch of fileChunks) {
          const uploadPayload = new FormData()
          uploadPayload.append("upload_job_id", uploadJobId)

          batch.forEach((file) => {
            if (file.originFileObj) {
              uploadPayload.append("models", file.originFileObj, file.fileName)
            }
          })

          if (unit) {
            const units = files.reduce((pre, cur) => {
              if (/(stp|step|igs|iges)$/i.test(cur.fileName || cur.name)) {
                return {
                  ...pre
                }
              } else {
                return {
                  ...pre,
                  [String(cur.fileName || cur.name)]: unit
                }
              }
            }, {})
            uploadPayload.append("models_units", JSON.stringify(units))
          }

          // Call API Upload Modal
          const { data: modelsData } = await uploadModals(uploadPayload)
          if (
            !modelsData ||
            modelsData.some((e) =>
              e.object_models?.some((f) => f.status === "failed")
            )
          ) {
            throw new Error(`Failed to upload file`)
          }

          uploadedFiles.push(...modelsData.flat())

          // Wait 2s then continue
          if (fileChunks.indexOf(batch) < fileChunks.length - 1) {
            await new Promise((res) => setTimeout(res, 2000))
          }
        }
        models = checkUploadModels(uploadedFiles, options, unit)

        if (option?.onlyUploadFiles) {
          const fileObj = (
            uploadedFiles
              .map<UploadFile | undefined>((e) => {
                const file = files.find((f) => e.file_name === f.name)
                if (file) {
                  return {
                    ...file,
                    status: e.detail ? "error" : "uploading",
                    error: e.detail,
                    response: {
                      orderId,
                      data: e
                    }
                  }
                }

                return file
              })
              .filter(Boolean) as UploadFile[]
          ).reduce((pre, cur) => ({ ...pre, [cur.uid]: cur }), {})

          return {
            orderId,
            files: fileObj
          }
        }
      }

      // CHECK STATUS
      const modelIds = models.flatMap((e) =>
        e.object_models.map((model) => model.id)
      )

      const result = await Promise.all(
        modelIds.map(async (model) => {
          const { failed, ready } = await checkStatus([model])

          const statusFailedModels = models.filter((e) =>
            failed.find((r) => r === e.object_models[0].id)
          )
          if (statusFailedModels.length && option?.uploadInterupted) {
            const statusFailedFiles = statusFailedModels
              .map<UploadFile | undefined>((e) => {
                const file = files.find((f) => e.file_name === f.name)
                if (file) {
                  return {
                    ...file,
                    status: "error",
                    error: "Model couldn't be analyzed"
                  }
                }

                return file
              })
              .filter(Boolean)

            dispatch(
              fileToProductUpdateExcludeError(
                (statusFailedFiles as UploadFile[]).reduce(
                  (pre, cur) => ({ ...pre, [cur.uid]: cur }),
                  {}
                )
              )
            )
            option.uploadInterupted(statusFailedFiles as UploadFile[])
            if (statusFailedFiles.length) {
              uploadDefaultModels(
                statusFailedFiles as UploadFile[],
                { ...options, onlyPurchase: false },
                unit
              )
            }
          }
          // PURCHASE
          if (!ready.length) {
            return {
              orderId,
              files: {}
            }
          }

          await checkModels(ready)
          const preselectData = await checkSuitableMaterial(ready, {})
          if (!preselectData) throw new Error("Failed to preselect materials")

          const configs = getConfig(preselectData)
          const failedPurchase: UploadFile[] = []
          const successPurchase: UploadFile[] = []
          await Promise.all(
            configs.map(async (config) => {
              const purchaseData = await retryOnDigifabsterTimeout(
                () =>
                  purchaseProduct({
                    orderId: orderId,
                    arg: { payload: { ...config, count: 1 }, country }
                  }).unwrap(),
                RETRY_TIME,
                RETRY_DELAY
              )
              if (!purchaseData) {
                const model = models.find(
                  (e) => e.object_models[0].id === config.model_id
                )
                const file = files.find((f) => f.name === model?.file_name)
                if (file) {
                  failedPurchase.push({
                    ...file,
                    status: "error",
                    error:
                      "The technology analysis process failed. Please try again or ",
                    response: {
                      orderId,
                      data: model
                    }
                  })
                }
                if (option?.uploadInterupted && failedPurchase.length) {
                  dispatch(
                    fileToProductUpdateExcludeError(
                      (failedPurchase as UploadFile[]).reduce(
                        (pre, cur) => ({ ...pre, [cur.uid]: cur }),
                        {}
                      )
                    )
                  )
                  option.uploadInterupted(failedPurchase)
                  await uploadDefaultModels(
                    failedPurchase as UploadFile[],
                    { ...options, onlyPurchase: false },
                    unit
                  )
                }

                return
              } else {
                const model = models.find(
                  (e) => e.object_models[0].id === config.model_id
                )
                const file = files.find((f) => f.name === model?.file_name)
                if (
                  config?.material_id === MANUAL_REQUEST_MATERIAL_ID ||
                  MANUAL_REQUEST_MATERIAL_IDS.includes(config?.material_id)
                ) {
                  await updatePurchaseNote({
                    orderId: orderId,
                    productId: purchaseData?.purchase_id,
                    arg: {
                      self_notes:
                        "This part can’t be produced with this technology."
                    }
                  })
                }
                if (file) {
                  const _file: UploadFile = {
                    ...file,
                    status: "done",
                    response: {
                      orderId,
                      data: model
                    }
                  }
                  successPurchase.push(_file)
                }
              }
            })
          )

          const fileObj = successPurchase.reduce<Record<string, UploadFile>>(
            (pre, cur) => ({ ...pre, [cur.uid]: cur }),
            {}
          )

          dispatch(fileToProductUpdate(fileObj))

          return {
            orderId,
            files: fileObj
          }
        })
      )
      return {
        orderId,
        files: result.reduce((acc, cur) => ({ ...acc, ...cur.files }), {})
      }
    } catch (err: any) {
      const fileObj = files.reduce<Record<string, UploadFile>>(
        (pre, cur) => ({
          ...pre,
          [cur.uid]: {
            ...cur,
            status: "error",
            error: "Upload failed, please try again or "
          }
        }),
        {}
      )

      dispatch(fileToProductUpdate(fileObj))
      return {
        files: fileObj,
        error: err.message || err
      }
    }
  }

  const uploadDefaultModels = async (
    files: UploadFile[],
    option: {
      orderId: number
      uploadJobId: string
      uploadInterupted?: (failedFile: UploadFile[]) => void
      onlyUploadFiles?: boolean
      onlyPurchase?: boolean
    },
    unit?: string
  ): Promise<IUploadData> => {
    const filesToUpload: UploadFile<any>[] = files.map((e, i) => {
      return {
        ...e,
        fileName: e.name,
        status: "uploading",
        name: `${e.name}.stl`
      }
    })
    dispatch(fileToProduct(filesToUpload))

    const checkUploadModels = (filesUploaded: UploadModalsResponse[]) => {
      const { failedModels, successModels } =
        getUploadSuccessModels(filesUploaded)

      if (option?.uploadInterupted && failedModels.length) {
        const failedFiles = failedModels
          .map<UploadFile | undefined>((e) => {
            const file = filesToUpload.find((f) => e.file_name === f.name)
            const errorMsg =
              e.detail?.includes("may only contain") ||
              e.detail?.includes("Allowed extensions")
                ? `Oops! The file format for ${file?.name} isn't supported. Please upload your part in .STEP format for the best results. We also support .STL, .OBJ, .WRL, .IGES, .DWG, and .DXF file types. If you believe this message is an error, please `
                : e.detail
            if (file) {
              return {
                ...file,
                status: "error",
                error: errorMsg || "Upload failed, please try again or "
              }
            }

            return file
          })
          .filter(Boolean)
        dispatch(
          fileToProductUpdate(
            (failedFiles as UploadFile[]).reduce(
              (pre, cur) => ({ ...pre, [cur.uid]: cur }),
              {}
            )
          )
        )
        option.uploadInterupted(failedFiles as UploadFile[])
      }

      return successModels
    }

    try {
      const { orderId, uploadJobId } = option
      let models: UploadModalsResponse[] = []

      const uploadedFiles: UploadModalsResponse[] = []
      const chunkSize = 5
      let results: UploadModalsResponse[] = []

      // Split files into groups of 5 files each.
      const fileChunks = []
      for (let i = 0; i < filesToUpload.length; i += chunkSize) {
        fileChunks.push(filesToUpload.slice(i, i + chunkSize))
      }

      for (const batch of fileChunks) {
        const uploadPayload = new FormData()

        batch.forEach((file) => {
          if (fileContent) {
            uploadPayload.append("models", fileContent, file.name)
          }
        })
        uploadPayload.append("upload_job_id", uploadJobId)

        if (unit) {
          const units = files.reduce((pre, cur) => {
            if (/(stp|step|igs|iges)$/i.test(cur.fileName || cur.name)) {
              return {
                ...pre
              }
            } else {
              return {
                ...pre,
                [String(cur.fileName || cur.name)]: unit
              }
            }
          }, {})
          uploadPayload.append("models_units", JSON.stringify(units))
        }

        const { data: modelsData } = await uploadModals(uploadPayload)

        if (!modelsData) throw new Error("Failed to upload models")

        results.push(...modelsData.flat())

        if (fileChunks.indexOf(batch) < fileChunks.length - 1) {
          await new Promise((res) => setTimeout(res, 2000))
        }
      }

      models = checkUploadModels(results)

      if (option?.onlyUploadFiles) {
        const fileObj = (
          uploadedFiles
            .map<UploadFile | undefined>((e) => {
              const file = filesToUpload.find((f) => e.file_name === f.name)
              if (file) {
                return {
                  ...file,
                  status: e.detail ? "error" : "uploading",
                  error: e.detail,
                  response: {
                    orderId,
                    data: e
                  }
                }
              }

              return file
            })
            .filter(Boolean) as UploadFile[]
        ).reduce((pre, cur) => ({ ...pre, [cur.uid]: cur }), {})

        return {
          orderId,
          files: fileObj
        }
      }

      // CHECK STATUS
      const modelIds = models.flatMap((e) =>
        e.object_models.map((model) => model.id)
      )

      const result = await Promise.all(
        modelIds?.map(async (model) => {
          const { failed, ready } = await checkStatus([model])

          const statusFailedModels = models.filter((e) =>
            failed.find((r) => r === e.object_models[0].id)
          )
          if (statusFailedModels.length && option?.uploadInterupted) {
            const statusFailedFiles = statusFailedModels
              .map<UploadFile | undefined>((e) => {
                const file = filesToUpload.find((f) => e.file_name === f.name)
                if (file) {
                  return {
                    ...file,
                    status: "error",
                    error: "Upload failed, please try again or "
                  }
                }

                return file
              })
              .filter(Boolean)

            dispatch(
              fileToProductUpdate(
                (statusFailedFiles as UploadFile[]).reduce(
                  (pre, cur) => ({ ...pre, [cur.uid]: cur }),
                  {}
                )
              )
            )
            option.uploadInterupted(statusFailedFiles as UploadFile[])
          }
          // PURCHASE
          if (!ready.length) {
            return {
              orderId,
              files: {}
            }
          }

          await checkModels(ready)
          const preselectData = await checkSuitableMaterial(ready, {})
          if (!preselectData) throw new Error("Failed to preselect materials")

          const configs = getConfig(preselectData)
          const failedPurchase: UploadFile[] = []
          const successPurchase: UploadFile[] = []
          await Promise.all(
            configs.map(async (config) => {
              const purchaseData = await retryOnDigifabsterTimeout(
                () =>
                  purchaseProduct({
                    orderId: orderId,
                    arg: {
                      payload: {
                        ...config,
                        count: 1,
                        manual_review_required: true
                      },
                      country
                    }
                  }).unwrap(),
                RETRY_TIME,
                RETRY_DELAY
              )
              if (!purchaseData) {
                const model = models.find(
                  (e) => e.object_models[0].id === config.model_id
                )
                const file = filesToUpload.find(
                  (f) => f.name === model?.file_name
                )
                if (file) {
                  failedPurchase.push({
                    ...file,
                    status: "error",
                    error:
                      "The technology analysis process failed. Please try again or ",
                    response: {
                      orderId,
                      data: model
                    }
                  })
                }
                if (option?.uploadInterupted && failedPurchase.length) {
                  dispatch(
                    fileToProductUpdate(
                      failedPurchase.reduce(
                        (pre, cur) => ({ ...pre, [cur.uid]: cur }),
                        {}
                      )
                    )
                  )
                  option.uploadInterupted(failedPurchase)
                }
                return
              } else {
                const model = models.find(
                  (e) => e.object_models[0].id === config.model_id
                )
                const file = filesToUpload.find(
                  (f) => f.name === model?.file_name
                )
                if (file) {
                  const _file: UploadFile = {
                    ...file,
                    status: "done",
                    response: {
                      orderId,
                      purchaseId: purchaseData.purchase_id,
                      data: model
                    }
                  }
                  successPurchase.push(_file)
                }
              }
            })
          )

          successPurchase.forEach(async (e) => {
            const uploadDrawingPayload = new FormData()
            if (!e.originFileObj) return
            uploadDrawingPayload.append("file", e.originFileObj, e.fileName)
            await uploadDrawings({
              order_id: orderId,
              purchase_id: e.response?.purchaseId,
              arg: uploadDrawingPayload
            })
          })
          const fileObj = successPurchase.reduce<Record<string, UploadFile>>(
            (pre, cur) => ({ ...pre, [cur.uid]: cur }),
            {}
          )
          dispatch(fileToProductUpdate(fileObj))

          return {
            orderId,
            files: fileObj
          }
        })
      )
      return {
        orderId,
        files: result.reduce((acc, cur) => ({ ...acc, ...cur.files }), {})
      }
    } catch (err: any) {
      const fileObj = filesToUpload.reduce<Record<string, UploadFile>>(
        (pre, cur) => ({
          ...pre,
          [cur.uid]: {
            ...cur,
            status: "error",
            error: "Upload failed, please try again or "
          }
        }),
        {}
      )

      dispatch(fileToProductUpdate(fileObj))
      return {
        files: fileObj,
        error: err.message || err
      }
    }
  }
  return { uploadModels }
}
