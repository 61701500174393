import { MANUAL_REQUEST_MATERIAL_ID } from "@/constants/order.constrant"
import "./TopOrderToolbar.scss"
import { Button } from "@/components"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { useCurrency } from "@/hooks/useCurrency"
import { Product } from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { EModelAnalysisStatus, IProductStore } from "@/store/product"
import { IQuoteStore } from "@/store/quote"
import { priceDisplay } from "@/utils/functionHelper"
import { useMemo } from "react"
import { useSelector } from "react-redux"

interface ITopOrderToolbarProps {
  data: Product[]
  onCheckOut: () => void
  onSaveQuote: () => void
  onSubmitReview: () => void
  onSubmitManualRequest: () => void
  isRequiresReview?: boolean
  DFMError?: boolean
  isLoadingQuote?: boolean
}
function TopOrderToolbar({
  data,
  onCheckOut,
  onSaveQuote,
  isRequiresReview,
  onSubmitManualRequest,
  onSubmitReview,
  DFMError
}: ITopOrderToolbarProps) {
  // TODO: mission price in productEntity
  const { quoteDetail, modelsAnalysis } = useSelector<
    RootState,
    IQuoteStore & IProductStore
  >((s) => ({
    ...s.quote,
    ...s.product
  }))
  const { isUploadingFile } = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  )
  const { currency } = useCurrency()
  const waitingPrice = useMemo(() => {
    return quoteDetail?.line_items?.reduce((acc, item) => {
      if (
        item.status === EOrderStatus.WAITING_FOR_REVIEW ||
        item.material === MANUAL_REQUEST_MATERIAL_ID
      ) {
        return acc + item.total_price
      }
      return acc
    }, 0)
  }, [quoteDetail])
  const disableBtnCheckout = data.length <= 0 || isUploadingFile || DFMError

  const errorPrice = useMemo(() => {
    return quoteDetail?.line_items?.reduce((acc, item) => {
      if (
        modelsAnalysis[item?.product?.id]?.status === EModelAnalysisStatus.ERROR
      ) {
        return acc + item.total_price
      }
      return acc
    }, 0)
  }, [modelsAnalysis])

  return (
    <div className="top-order-toolbar-container">
      <p className="total-text">
        Order total ({data.length} {data.length > 1 ? "items" : "item"}):
      </p>
      <p className="price-text">
        {currency}
        {priceDisplay(
          Number(quoteDetail?.models_naked_price) - (waitingPrice || 0)
        )}
      </p>
      <Button
        customSize={Size.MEDIUM}
        customClassName={"button-checkout"}
        onClick={isRequiresReview ? onSubmitReview : onCheckOut}
        disabled={disableBtnCheckout}
      >
        {isRequiresReview ? "Submit for Review" : "Proceed To Checkout"}
      </Button>
    </div>
  )
}

export default TopOrderToolbar
