import BackToLogin from "../components/BackToLogin/BackToLogin"
import EmailResetPWForm from "../components/Form/EmailResetPWForm"

function EmailResetPassword(): JSX.Element {
  return (
    <div className="forgot-pw-page-email">
      <div
        className="forgot-pw-page-email-title"
        style={{ marginBottom: "40px", fontFamily: '"Open Sans", sans-serif' }}
      >
        We will send you an email with a link to reset your password
      </div>
      <EmailResetPWForm></EmailResetPWForm>
      <BackToLogin />
    </div>
  )
}

export default EmailResetPassword
